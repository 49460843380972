const setMarque = {
  // ! *** Do not modify default setting unless it's really necessary ***
  // Put `window.location.hostname` in the browser console to get the hostname.
  // in the header, logo height is 60px, if it's a png, put a bigger image to get better image quality.
  // logoEtreRappele = the big logo at the end of parcours(Q38)
  // showHeader = true or false
  // removeQ1 = [1,2,3] an array. for example removing "Installer des panneaux solaires", that's [4].
  // headerBgcolor/color1/color2 = color codes (eg: '#00000') or color names (eg: 'pink')
  // logoMarqueWidth format = 200 or '200px'.

  default: {
    marque: "Soregies",
    favicon: "/favicon.ico",
    showHeader: true,
    headerBgcolor: "white",
    color1: "#001F3B",
    color2: "#F07C71",
    removeQ1: [],
    logoMarque: "logo-soregies-groupe.svg",
    logoMarqueWidth: 200,
    logoMarquePadding: "10px",
    logoEtreRappele: "logo-soregies-groupe.svg",
    avisVerifies: false,
    environments: {
      prod: {
        apiUrl:
          "https://api-simulateur-soregies.hellio.com/simulateurb2c/soregies",
        apiToken: "EWbeHfDFwgbWH92PFU2pSnVaP5tjHrmkvrtrg4jn",
        simulatorUrl: "https://simulateur-primes.soregies.fr",
        customerAccountUrl: "https://espace-beneficiaire.soregies.fr",
        cryptoKey: "f71902eb-d771-4772-86d9-c590c7f9060b",
      },
      prodAlias: {
        apiUrl:
          "https://api-simulateur-soregies.hellio.com/simulateurb2c/soregies",
        apiToken: "EWbeHfDFwgbWH92PFU2pSnVaP5tjHrmkvrtrg4jn",
        simulatorUrl: "https://simulateur-soregies.pages.dev",
        customerAccountUrl: "https://espace-beneficiaire.soregies.fr",
        cryptoKey: "f71902eb-d771-4772-86d9-c590c7f9060b",
      },
      staging: {
        apiUrl:
          "https://staging-simulateurb2c.hellio.com/simulateurb2c/soregies",
        apiToken: "EWbeHfDFwgbWH92PFU2pSnVaP5tjHrmkvrtrg4jn",
        simulatorUrl: "https://staging.simulateur-soregies.pages.dev/",
        customerAccountUrl:
          "https://staging.espace-beneficiaire-primes-soregies.hellio.dev",
        cryptoKey: "f71902eb-d771-4772-86d9-c590c7f9060b",
      },
      dev: {
        apiUrl: "https://dev-simulateurb2c.hellio.com/simulateurb2c/soregies",
        apiToken: "EWbeHfDFwgbWH92PFU2pSnVaP5tjHrmkvrtrg4jn",
        simulatorUrl: "https://dev.simulateur-soregies.pages.dev/",
        customerAccountUrl:
          "https://dev.espace-beneficiaire-primes-soregies.hellio.dev",
        cryptoKey: "f71902eb-d771-4772-86d9-c590c7f9060b",
      },
      local: {
        apiUrl: "http://localhost:8080/simulateurb2c/soregies",
        apiToken: "EWbeHfDFwgbWH92PFU2pSnVaP5tjHrmkvrtrg4jn",
        simulatorUrl: "http://localhost:3001",
        customerAccountUrl:
          "http://espace-beneficiaire-primes.soregies.local:3000",
        cryptoKey: "f71902eb-d771-4772-86d9-c590c7f9060b",
      },
    },
  },
};
export default setMarque;
