import React from "react";

class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileSelected: false,
      file: {
        name: "",
        date: "",
        size: "",
      },
    };
  }

  formatBytes = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
  };

  formatName = (name) => {
    if (name.length < 14) return name;
    else return name.substring(0, 10) + ".." + name.substring(name.length - 4);
  };

  handleChangeFile = (file) => {
    this.handleFileInfo(file);
    const fileData = new FileReader();
    fileData.onloadend = this.props.handleFile;
    fileData.readAsDataURL(file);
  };

  handleFileInfo = (file) => {
    const filedate = new Date(file.lastModifiedDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const FILE = this.state.file;

    FILE.date = filedate.toLocaleDateString("fr-FR", options);
    FILE.name = file.name;
    FILE.size = this.formatBytes(file.size);

    this.setState({
      file: FILE,
      fileSelected: true,
    });
  };

  render() {
    // console.log(this.props.patchName)
    return (
      <div className="pv-10">
        <div className="file-uploader-container">
          <div className="align-center">
            <div className="uploader-paragraph">
              <p>{this.props.description}</p>
            </div>

            <label className="btn-file-uploader is-bold">
              <input
                type="file"
                accept=".pdf"
                name={this.props.patchName}
                onChange={(e) =>
                  this.handleChangeFile(e.target.files[0], e.target.name)
                }
                style={{ opacity: 0 }}
              />
              Ajouter un document
              <span className="iconColor1">
                <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-10"
                >
                  <path
                    d="M3.03223 4.12977L3.9466 5.05102L6.35286 2.66883V8.60197H7.6488V2.66883L10.0516 5.05102L10.966 4.12977L6.99911 0.197266L3.03223 4.12977Z"
                    fill="#006FBA"
                  />
                  <path
                    d="M13.8666 12.8019H0.133789V6.85156H1.51223V11.4166H12.4882V6.85156H13.8666V12.8019Z"
                    fill="#006FBA"
                  />
                </svg>
              </span>
            </label>
          </div>
          <div className="mv-10"></div>
          <div>
            {this.state.fileSelected && (
              <div>
                <p
                  style={{
                    fontSize: "10px",
                    textAlign: "left",
                  }}
                  className="mt-20"
                >
                  1 fichier importé
                </p>
                <hr className="light-hr" />
                <div style={{ fontSize: "12px" }}>
                  <span>{this.formatName(this.state.file.name)}</span>
                  <span
                    style={{ float: "right", color: "rgba(58, 58, 58, 1)" }}
                  >
                    {this.state.file.date} - {this.state.file.size}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FileUploader;
